import React from "react";

import { Container, Card, Typography } from "@material-ui/core";

import Menu from "./menu";
import SessionPanel from "./sessionPanel";
import Footer from "../footer";
import LoadingPage from "../loadingPage";

const MenuPage = ({ phone }) => {
  return (
    <Container disableGutters maxWidth="false">
      <Card
        style={{ backgroundColor: "#4da3fa", padding: "5% 0" }}
        square
        elevation={0}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            // align="center"
            style={{
              marginRight: "auto",
              fontSize: phone ? null : "10rem",
              // paddingRight: "20px",
            }}
          >
            Menu
          </Typography>

          <br />
          <br />
          <Typography
            variant="h6"
            style={{
              maxWidth: "800px",
              marginLeft: "auto",
              fontSize: phone ? "1rem" : null,
            }}
          >
            Welcome to the CleanCrust menu. Scroll down to see all the pizzas we
            have on our menu.
          </Typography>
          <br />
          <br />

          <div id="#sessionPanelBottom"></div>
          <br />
          <br />
        </Container>
      </Card>
      <div id="#menu"></div>
      <Menu displayWholeMenu />
      <Footer />
    </Container>
  );
};

export default MenuPage;
