import axios from "axios";
import { url } from "./apiurl";

export const getDiscounts = () =>
  axios.get(url + "/discount", { withCredentials: true });

export const checkDiscount = (code) =>
  axios.get(url + "/discount/" + code, { withCredentials: true });

export const createNewDiscount = (discount) =>
  axios.post(url + "/discount", discount, { withCredentials: true });
