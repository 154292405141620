import axios from "axios";
import { url } from "./apiurl";

//--------------------GET--------------------//
export const getUsers = () =>
  axios.get(url + "/user", { withCredentials: true });

//--------------------PATCH-------------------//
export const updateUserName = (name) =>
  axios.patch(url + "/user/update/name/" + name, { withCredentials: true });

export const updateSubscription = (subscribe) =>
  axios.patch(url + "/user/update/subscribe/" + subscribe, {
    withCredentials: true,
  });
//--------------------Post-------------------//
export const createNewUser = (body) =>
  axios.post(url + "/user", { ...body }, { withCredentials: true });

export const logoutUser = () =>
  axios.post(url + "/user/logout", { withCredentials: true });

export const loginUser = (body) =>
  axios.post(url + "/user/login", body, { withCredentials: true });

export const requestPasswordReset = (body) =>
  axios.post(url + "/user/request-password-reset", body, {
    withCredentials: true,
  });
export const passwordReset = (body) =>
  axios.post(
    url + "/user/password-reset",
    { ...body },
    { withCredentials: true }
  );
//--------------------Delete-----------------//
export const deleteUser = (id) =>
  axios.delete(url + "/user/" + id, { withCredentials: true });
