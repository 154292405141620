import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box, Grid } from "@material-ui/core";

const LoadingMenuCard = (props) => {
  return (
    <Grid container direction="column" style={{ maxWidth: "350px" }}>
      <Grid item>
        <Skeleton variant="rect" width={350} height={250} />
      </Grid>

      <Grid item>
        <Skeleton width="60%" />

        <Skeleton />
        <Skeleton />
      </Grid>
    </Grid>
  );
};

export default LoadingMenuCard;
