import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import Navbar from "./components/navBar";
import LoadingPage from "./components/loadingPage";
import { useMediaQuery } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { OrderPage, MenuPage } from "./components/order";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import PrivacyPolicy from "./components/privacyPolicy";
import { loginUser } from "./api/user-apis";
import TermsAndConditions from "./components/termsAndConditions";
import GenericError from "./components/genericError";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";

const CateringPage = React.lazy(() => import("./components/cateringPage"));
const WorkshopPage = React.lazy(() => import("./components/workshopPage"));
const Home = React.lazy(() => import("./components/home"));
const AdminPage = React.lazy(() => import("./components/admin/adminPage"));
const Thankyou = React.lazy(() => import("./components/thankyou"));
const About = React.lazy(() => import("./components/about"));
const EthicalAndSustainable = React.lazy(() =>
  import("./components/ethicalAndSustainable")
);
const UserPage = React.lazy(() => import("./components/user/userPage.jsx"));

function App() {
  const [fetchingUser, setFetchingUser] = useState(true);
  const [user, setUser] = useState(null);
  // const axiosErrors = useRef([]);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    loginUser()
      .then((res) => {
        setFetchingUser(false);
        setUser(res.data);
      })
      .catch((err) => {
        setFetchingUser(false);
      });
  }, []);

  const iPhoneInput = {
    styleOverrides: {
      root: {
        "*": {
          "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
          "-moz-user-select": "text !important" /* Firefox 2+ */,
          "-ms-user-select": "text !important" /* IE 10+ */,
          "user-select": "text !important" /* Standard syntax */,
        },
      },
    },
  };

  const theme = createTheme({
    typography: {
      h1: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h2: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h3: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h4: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h5: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h6: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    },
    palette: {
      primary: {
        main: "#4da3fa",
      },
    },
    components: {
      MuiTextField: iPhoneInput,
      MuiInput: iPhoneInput,
      MuiFilledInput: iPhoneInput,
      MuiOutlinedInput: iPhoneInput,
    },
  });

  const phone = !useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div style={{ height: "100%" }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Navbar />

          <Suspense fallback={LoadingPage}>
            <Switch>
              <Route path="/order/:id">
                <Helmet>
                  <title>Order</title>
                  <meta
                    name="description"
                    content="Order the best pizza in York from the CleanCrust Pizza Truck. Enjoy fresh, delicious Neapolitan-style pizza delivered fast to your door. Easy online ordering and quick delivery from our manu pop-ups in the York area. Place your order now!"
                  />
                  <link rel="canonical" href="/order" />
                </Helmet>
                <OrderPage phone={phone} user={user} />
              </Route>
              <Route path="/order" exact>
                <Redirect to="/order/now" />
              </Route>
              <Route path="/menu">
                <Helmet>
                  <title>Menu</title>
                  <meta
                    name="description"
                    content="Welcome to the menu page for CleanCrust. See a list of all our Neapolitan-style pizzas we make for our local pop-ups and events."
                  />
                  <link rel="canonical" href="/menu" />
                </Helmet>
                <MenuPage phone={phone} />
              </Route>
              <Route path="/admin">
                <Helmet>
                  <title>Admin</title>
                  <link rel="canonical" href="/admin" />
                  <meta name="robots" content="noindex" />
                </Helmet>
                <AdminPage phone={phone} user={user} />
              </Route>
              <Route path="/login">
                <Redirect to="/user/login" />
              </Route>
              <Route path="/user">
                <Helmet>
                  <title>User</title>
                  <link rel="canonical" href="/user" />
                  <meta name="robots" content="noindex" />
                </Helmet>
                <UserPage
                  phone={phone}
                  user={user}
                  setUser={setUser}
                  fetchingUser={fetchingUser}
                />
              </Route>
              <Route path="/catering">
                <Helmet>
                  <title>Catering</title>
                  <meta
                    name="description"
                    content="Book the CleanCrust Pizza Truck for your next event in York! Our mobile pizza van offers fresh, delicious Neapolitan-style pizzas made on-site. Perfect for parties, weddings, and corporate events. Contact us for the best catering services in York!"
                  />
                  <link rel="canonical" href="/catering" />
                </Helmet>
                <CateringPage phone={phone} />
              </Route>
              <Route path="/workshops">
                <Helmet>
                  <title>Workshops</title>
                  <meta
                    name="description"
                    content="Welcome to the Workshop page for CleanCrust. Read about the workshop package CleanCrust provides."
                  />
                  <link rel="canonical" href="/workshops" />
                </Helmet>
                <WorkshopPage phone={phone} />
              </Route>
              <Route path="/thankyou">
                <Helmet>
                  <title>Thank You</title>
                  <link rel="canonical" href="/thankyou" />
                  <meta name="robots" content="noindex" />
                </Helmet>
                <Thankyou phone={phone} />
              </Route>
              <Route path="/about" exact>
                <Helmet>
                  <title>About</title>
                  <meta
                    name="description"
                    content="Discover the story behind the CleanCrust Pizza Truck. Learn about our commitment to serving the best, delicious Neapolitan-style pizzas in York. Meet our passionate team and find out what makes our mobile pizza van special. Get to know us today! "
                  />
                  <link rel="canonical" href="/about" />
                </Helmet>
                <About phone={phone} />
              </Route>
              <Route path="/ethicalandsustainable" exact>
                <Helmet>
                  <title>Ethical and Sustainable</title>
                  <meta
                    name="description"
                    content="Learn about what CleanCrust does to be ethical and sustainable. Read about the core principles of CleanCrust. "
                  />
                  <link rel="canonical" href="/ethicalandsustainable" />
                </Helmet>
                <EthicalAndSustainable phone={phone} />
              </Route>
              <Route path="/privacypolicy" exact>
                <Helmet>
                  <title>Privacy Policy</title>
                  <meta
                    name="description"
                    content="Learn about CleanCrust's Privacy Policy."
                  />
                  <link rel="canonical" href="/privacypolicy" />
                </Helmet>
                <PrivacyPolicy phone={phone} />
              </Route>
              <Route path="/termsandconditions" exact>
                <Helmet>
                  <title>Terms and Conditions</title>
                  <meta
                    name="description"
                    content="Learn about CleanCrust's Terms and Conditions."
                  />
                  <link rel="canonical" href="/termsandconditions" />
                </Helmet>
                <TermsAndConditions phone={phone} />
              </Route>
              <Route path="/" exact>
                <Helmet>
                  <title>Home</title>
                  <meta
                    name="description"
                    content="Looking for pizza catering in York? the CleanCrust Pizza Truck offers fresh, mouth-watering pizzas made fresh. Perfect for parties, weddings, and corporate events. Book our mobile pizza van today for an unforgettable catering experience!"
                  />
                  <link rel="canonical" href="/" />
                </Helmet>
                <Home phone={phone} />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
