import React, { useState } from "react";

import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemSecondaryAction,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const AddToBasketModal = ({ show, onClose, onSubmit, pizza, deal, phone }) => {
  const [modifications, setModifications] = useState([]);

  const calcPrice = () => {
    let price = pizza.price;

    modifications.forEach((index) => {
      price = price + pizza.modifications[index].price;
    });

    return formatter.format(price);
  };

  const handleModificationClick = (modificationIndex) => {
    const index = modifications.indexOf(modificationIndex);
    let newModifications = [...modifications];
    if (index !== -1) {
      newModifications.splice(index, 1);
    } else {
      newModifications.push(modificationIndex);
    }
    setModifications(newModifications);
  };
  const handleClose = () => {
    setModifications([]);
    onClose();
  };
  const handleSubmit = () => {
    handleClose();
    onSubmit(modifications);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      fullScreen={deal ? false : phone}
    >
      <DialogTitle
        id="customized-dialog-title"
        style={{ minWidth: "300px" }}
        align="center"
      >
        <Grid container justify="space-between">
          <Grid>{pizza.name}</Grid>
          <Grid>{deal ? null : formatter.format(pizza.price)}</Grid>
        </Grid>
      </DialogTitle>
      {deal ? (
        <>
          <DialogContent dividers>
            <Typography>Would you like to add this to your deal?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={handleSubmit}
            >
              Add to Deal
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent dividers>
            <Typography variant="h6">Description</Typography>
            <Typography style={{ maxWidth: "400px" }}>
              {pizza.description}
            </Typography>
            <br />
            <Typography variant="h6">
              Would you like to add extra toppings?
            </Typography>
            <List>
              {pizza.modifications
                .sort((a, b) => a.price - b.price)
                .map((item, index) => (
                  <Mod
                    {...item}
                    modifications={modifications}
                    index={index}
                    onClick={() => handleModificationClick(index)}
                  />
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={handleSubmit}
            >
              Add For {calcPrice()}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const Mod = ({ name, price, _id, modifications, onClick, index }) => {
  return (
    <ListItem dense button onClick={onClick}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={modifications.indexOf(index) !== -1}
          tabIndex={-1}
          disableRipple
          color="primary"
        />
      </ListItemIcon>

      <ListItemText primary={name} />
      <ListItemSecondaryAction>
        <Typography variant="body2" color="textSecondary">
          (+{formatter.format(price)})
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AddToBasketModal;
