import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import "yup-phone";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  InputLabel,
  Input,
  InputAdornment,
  FormControl,
  FormHelperText,
  CircularProgress,
  Divider,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
  Chip,
  IconButton,
  Switch,
  Slider,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Formik, useFormikContext, Form } from "formik";
import Basket from "./basket";
import { checkDiscount } from "../../api/discount-apis";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const SubmitForm = ({
  open,
  onClose,
  onSubmit,
  submitting,
  basket,
  deals,
  menu,
  handleRemoveDealFromBasket,
  handleRemovePizzaFromBasket,
  time,
  handleTimeChange,
  session,
  postcode,
  deliveryFee,
  price,
  phone,
  user,
  frontOfHouse,
}) => {
  const [discount, setDiscount] = useState({
    code: "",
    value: 0,
    percentage: true,
  });
  const timeSlots = session.timeSlots;
  const theme = useTheme();
  const formatAddress = (addressObject) => ({
    addressLine1: addressObject.addressLine1,
    addressLine2: addressObject.addressLine2 ? addressObject.addressLine2 : "",
    townOrCity: addressObject.townOrCity,
    postcode: postcode,
    deliveryFee: deliveryFee,
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async (values, { resetForm }) => {
    const submittedValues = {
      name: values.name,
      number: values.number,
      confirmNumber: values.confirmNumber,
      address: postcode ? formatAddress(values) : undefined,
      message: values.message,
      discount: discount,
    };

    await onSubmit(submittedValues);
    resetForm();
    setDiscount({
      code: "",
      value: 0,
      percentage: true,
    });
    handleTimeChange("");
    // onClose();
  };

  const handleDiscount = (discount) => {
    setDiscount(discount);
  };

  const calcPrice = () => {
    if (!discount) return formatter.format(price);

    let newPrice = price;
    if (discount.percentage) {
      newPrice = newPrice * ((100 - discount.value) / 100);
    } else {
      newPrice = newPrice - discount.value;
      if (newPrice < 0) newPrice = 0;
    }
    return newPrice;
  };

  if (!session || !Yup.string) return null;
  const schema = Yup.object({
    name: Yup.string()
      .max(100, "name must be less than 100 characters")
      .required("This field is required"),
    number: frontOfHouse
      ? Yup.string()
      : Yup.string().phone("GB").required("Phone number is required"),
    confirmNumber: frontOfHouse
      ? undefined
      : Yup.string()
          .phone("GB")
          .required("Phone number is required")
          .oneOf([Yup.ref("number"), null], "Phone number must match"),
    townOrCity: postcode ? Yup.string().required() : undefined,
    addressLine1: postcode ? Yup.string().required() : undefined,
    addressLine2: postcode ? Yup.string() : undefined,
    message: Yup.string(),
  });
  const cost = calcPrice();
  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        name: user ? user.name : "",
        number: user ? user.number.slice(3) : "",
        confirmNumber: frontOfHouse
          ? undefined
          : user
          ? user.number.slice(3)
          : "",
        townOrCity: postcode ? "" : undefined,
        addressLine1: postcode ? "" : undefined,
        addressLine2: postcode ? "" : undefined,
        message: "",
      }}
    >
      {({ handleSubmit, handleChange, values, isValid, errors }) => (
        <Dialog open={open} onClose={onClose} fullScreen={phone}>
          <DialogTitle
            style={{ minWidth: phone ? null : "400px", paddingTop: "25px" }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={2}>
                <IconButton onClick={onClose}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item xs={8}>
                Submit Your Order
              </Grid>
              <Grid item xs={2}>
                {formatter.format(cost)}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Divider />
            <br />
            <Typography variant="h6" align="center">
              Discount
            </Typography>
            <br />
            {frontOfHouse ? (
              <FrontOfHouseDiscountForm
                onSuccess={handleDiscount}
                discount={discount}
                setDiscount={setDiscount}
                cost={price}
              />
            ) : (
              <DiscountForm
                onSuccess={handleDiscount}
                discounts={user?.discounts}
              />
            )}

            <br />
            <Typography variant="h6" align="center">
              Basket
            </Typography>
            <br />
            <Basket
              basket={basket}
              deals={deals}
              menu={menu}
              handleRemoveDealFromBasket={handleRemoveDealFromBasket}
              handleRemovePizzaFromBasket={handleRemovePizzaFromBasket}
              postcode={postcode}
              deliveryFee={deliveryFee}
              discount={discount}
              price={price}
            />
            <Divider />

            <br />
            <Form>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="stretch"
              >
                <Typography variant="h6" align="center">
                  Details
                </Typography>

                <br />
                <Grid>
                  <FormControl fullWidth error={!!errors.name}>
                    <InputLabel>Name</InputLabel>
                    <Input
                      id="name"
                      onChange={handleChange}
                      value={values.name}
                      aria-describedby="name"
                      fullWidth
                      autoFocus={frontOfHouse}
                    />
                    <FormHelperText id="name-error-text">
                      {errors.name}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl fullWidth error={!!errors.number}>
                    <InputLabel>Number</InputLabel>
                    <Input
                      id="number"
                      onChange={handleChange}
                      value={values.number}
                      aria-describedby="number"
                      fullWidth
                      startAdornment={
                        <InputAdornment position="start">+44</InputAdornment>
                      }
                    />
                    <FormHelperText id="number-error-text">
                      {errors.number}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {frontOfHouse ? null : (
                  <Grid>
                    <FormControl fullWidth error={!!errors.confirmNumber}>
                      <InputLabel>Confirm Number</InputLabel>
                      <Input
                        id="confirmNumber"
                        onChange={handleChange}
                        value={values.confirmNumber}
                        aria-describedby="confirmNumber"
                        fullWidth
                        startAdornment={
                          <InputAdornment position="start">+44</InputAdornment>
                        }
                      />
                      <FormHelperText id="number-error-text">
                        {errors.confirmNumber}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                {timeSlots ? (
                  <Grid>
                    <FormControl fullWidth>
                      <InputLabel>
                        {postcode
                          ? "Estimated Delivery Time:"
                          : "Pick-up Time:"}
                      </InputLabel>
                      <Select
                        labelId="time-select-label"
                        id="time-select"
                        value={time}
                        onChange={(e) => handleTimeChange(e.target.value)}
                        fullWidth
                        style={{ minWidth: "60px" }}
                      >
                        {session.times.map((time) => (
                          <MenuItem value={time} key={time}>
                            {time}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {postcode ? (
                  <>
                    <br />
                    <Typography variant="h6" align="center">
                      Address
                    </Typography>
                    <Grid>
                      <FormControl fullWidth error={!!errors.addressLine1}>
                        <InputLabel>Address Line 1</InputLabel>
                        <Input
                          id="addressLine1"
                          onChange={handleChange}
                          value={values.addressLine1}
                          aria-describedby="address"
                          fullWidth
                        />
                        <FormHelperText id="address-line-1-error-text">
                          {errors.addressLine1}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl fullWidth error={!!errors.addressLine2}>
                        <InputLabel>Address Line 2 (optional)</InputLabel>
                        <Input
                          id="addressLine2"
                          onChange={handleChange}
                          value={values.addressLine2}
                          aria-describedby="address"
                          fullWidth
                        />
                        <FormHelperText id="address-line-2-error-text">
                          {errors.addressLine2}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl fullWidth error={!!errors.townOrCity}>
                        <InputLabel>Town/City</InputLabel>
                        <Input
                          id="townOrCity"
                          onChange={handleChange}
                          value={values.townOrCity}
                          aria-describedby="townOrCity"
                          fullWidth
                        />
                        <FormHelperText id="townOrCity-error-text">
                          {errors.townOrCity}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl fullWidth>
                        <InputLabel>Postcode</InputLabel>
                        <Input
                          id="postcode"
                          // onChange={handleChange}
                          value={postcode}
                          aria-describedby="postcode"
                          readOnly={true}
                          fullWidth
                        />
                        <FormHelperText id="postcode-error-text">
                          If you would like to change the postcode please begin
                          your order again by refreshing the page.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <br />
                  </>
                ) : null}
                <br />
                <Typography variant="h6" align="center">
                  Extra
                </Typography>
                <Grid>
                  <FormControl fullWidth error={!!errors.message}>
                    <InputLabel>Additional Infomation</InputLabel>
                    <Input
                      id="message"
                      onChange={handleChange}
                      value={values.message}
                      aria-describedby="message"
                      multiline
                      fullWidth
                      rows={4}
                    />
                    <FormHelperText id="message-error-text">
                      {errors.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions style={{ padding: 0 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={submitting}
              style={{
                width: "100%",
                height: "50px",
                // color: disabled ? null : "white",
                // borderRadius: 0,
              }}
              onClick={handleSubmit}
            >
              {frontOfHouse ? "Checkout" : "Checkout with Stripe"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

const DiscountForm = ({ onSuccess, discounts = [] }) => {
  const [fetching, setFetching] = useState(false);
  const [code, setCode] = useState("");
  const [discountSet, setDiscountSet] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (discountSet) {
      setDiscountSet(false);
      handleSubmit();
    }
  }, [code]);

  const handleDiscountClick = async (discount) => {
    setDiscountSet(true);
    setCode(discount.code);
  };

  const handleSubmit = () => {
    setError("");
    if (code.length === 0) {
      return;
    }
    setFetching(true);
    checkDiscount(code)
      .then((res) => {
        onSuccess(res.data);
        setFetching(false);
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined"
            ? err?.response?.data?.message
            : err.message;
        setError(message);

        setFetching(false);
      });
  };
  let filteredDiscounts = discounts.filter(
    (discount, index) =>
      discount.value === discounts[index].value &&
      discount.percentage === discounts[index].percentage &&
      discount.usage < discount.allowedUsage
  );
  filteredDiscounts = filteredDiscounts.slice(-3);
  return (
    <Grid container direction="column">
      <Grid container justifyContent="center">
        <TextField
          error={error}
          // helperText={error}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          label="Discount Code"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={fetching ? null : handleSubmit}
          // disabled={fetching}
        >
          {fetching ? <CircularProgress style={{ color: "white" }} /> : "Apply"}
        </Button>
      </Grid>
      <br />
      {discounts.length ? (
        <Typography
          variant="body2"
          align="center"
          style={{ marginBottom: "10px" }}
        >
          Discounts we found on your account:
        </Typography>
      ) : null}

      <Grid container justifyContent="space-around">
        {filteredDiscounts.map((discount) => (
          <Chip
            color={discount.code === code ? "primary" : "default"}
            icon={
              discount.percentage ? (
                <img
                  src="/assets/percentDiscount.png"
                  style={{ height: "24px" }}
                />
              ) : (
                <img
                  src="/assets/poundDiscount.png"
                  style={{ height: "24px" }}
                />
              )
            }
            label={
              <Typography>
                <strong>{`${discount.percentage ? "" : "£"}${discount.value}${
                  discount.percentage ? "%" : ""
                } Discount`}</strong>
              </Typography>
            }
            onClick={() => handleDiscountClick(discount)}
          />
        ))}
      </Grid>
    </Grid>
  );
};
const FrontOfHouseDiscountForm = ({
  onSuccess,
  discount,
  setDiscount,
  cost,
}) => {
  const [fetching, setFetching] = useState(false);
  const [code, setCode] = useState("");
  const [discountSet, setDiscountSet] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (discountSet) {
      setDiscountSet(false);
      handleSubmit();
    }
  }, [code]);

  const handleSubmit = () => {
    setError("");
    if (code.length === 0) {
      return;
    }
    setFetching(true);
    checkDiscount(code)
      .then((res) => {
        onSuccess(res.data);
        setFetching(false);
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined"
            ? err?.response?.data?.message
            : err.message;
        setError(message);

        setFetching(false);
      });
  };

  const handleTogglePercentage = () => {
    let newValue = 0;
    if (discount.percentage) {
      newValue = (cost * discount.value) / 100;
    } else {
      newValue = (discount.value * 100) / cost;
    }
    setCode("");
    setDiscountSet(false);
    setDiscount({
      code: "",
      value: newValue,
      percentage: !discount.percentage,
    });
  };
  const handleValueChange = (e, value) => {
    setCode("");
    setDiscountSet(false);
    setDiscount({ ...discount, code: "", value: value });
  };

  return (
    <Grid container direction="column">
      <Grid container justifyContent="center">
        <TextField
          error={error}
          // helperText={error}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          label="Discount Code"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={fetching ? null : handleSubmit}
          // disabled={fetching}
        >
          {fetching ? <CircularProgress style={{ color: "white" }} /> : "Apply"}
        </Button>
      </Grid>
      <br />
      <br />
      <Grid container direction="column" justifyContent="center">
        <Typography
          variant="h4"
          align="center"
          style={{ marginBottom: "10px" }}
        >
          {`${discount.percentage ? "" : "£"}${discount.value}${
            discount.percentage ? "%" : ""
          } off`}
        </Typography>
        <Slider
          defaultValue={0}
          aria-labelledby="discount-slider"
          step={1}
          min={0}
          max={discount.percentage ? 100 : cost}
          valueLabelDisplay="auto"
          value={discount.value}
          onChange={handleValueChange}
        />
        <br />
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h5">£</Typography>
          </Grid>
          <Switch
            color="primary"
            checked={discount.percentage}
            onChange={handleTogglePercentage}
          />
          <Grid item>
            <Typography variant="h5">%</Typography>
          </Grid>
        </Grid>
      </Grid>
      <br />
    </Grid>
  );
};

export default SubmitForm;
