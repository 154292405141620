import axios from "axios";
import { url } from "./apiurl";

export const getMenu = () =>
  axios.get(url + "/menu", { withCredentials: true });
export const getMenuById = (id) =>
  axios.get(url + "/menu/" + id, { withCredentials: true });
export const createMenu = (menu) =>
  axios.post(url + "/menu", menu, { withCredentials: true });
export const editMenu = (id, menu) =>
  axios.patch(url + `/menu/${id}`, menu, { withCredentials: true });
