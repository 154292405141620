import moment from "moment";

export const isDateInPast = (date) => {
  // console.log(startDate);
  return moment(date).isBefore(moment());
};

export const setTimeInDate = (date, time) => {
  if (!time) return;
  const hour = time.slice(0, 2);
  const min = time.slice(3, 5);
  // console.log(hour, min);
  return moment(date).set("hour", hour).set("minute", min);
};

export const filterTimes = (sessionDate, times) => {
  return times.filter(
    (time) => !isDateInPast(setTimeInDate(sessionDate, time))
  );
};

export const hasSessionEnded = ({ date, times, endDate }) => {
  if (endDate) return isDateInPast(endDate);
  if (!times.length) return isDateInPast(date);

  const finalTime = times[times.length - 1];

  return isDateInPast(setTimeInDate(date, finalTime));
};

export const isSessionActive = (session) => {
  return !hasSessionEnded(session) && isDateInPast(session.date);
};

export const sortTimes = (timeA, timeB) => {
  const a = setTimeInDate(moment(), timeA);
  const b = setTimeInDate(moment(), timeB);
  return a - b;
};
