import { React, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Container, Card } from "@material-ui/core";

import LoadingPage from "../loadingPage";

import Footer from "../footer";

import Menu from "./menu";
import SessionPanel from "./sessionPanel";
import NetworkErrorDisplay from "../networkError/networkErrorDisplay";
//functions
import axios from "axios";
import {
  getActiveSessions,
  getDeliveryFee,
  getWaitingTime,
} from "../../api/session-apis";
import { createOrder } from "../../api/order-apis";
import { hasSessionEnded } from "../../functions/timeFunctions";

import moment from "moment";
import RedirectPage from "./stripe/redirectPage";

const OrderPage = ({ phone, user }) => {
  let history = useHistory();
  let params = useParams();
  const [sessions, setSessions] = useState([]);
  const [fetchingSessions, setFetchingSessions] = useState(true);
  const [stripeRedirect, setStripeRedirect] = useState(false);
  const [networkErrors, setNetworkErrors] = useState([]);

  //order card states
  const [time, setTime] = useState("");
  const [postcode, setPostcode] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [orderCardComplete, setOrderCardComplete] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [orderCardErrorMessage, setOrderCardErrorMessage] = useState("");
  const [waitingTime, setWaitingTime] = useState(0);

  useEffect(() => {
    getActiveSessions()
      .then((res) => {
        if (!res.data.length) {
          setSessions([]);
          return setFetchingSessions(false);
        }
        const filteredSessions = res.data
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .filter((session) => !hasSessionEnded(session));

        const session = filteredSessions.find((item) => item._id === params.id);

        const newFilteredSessions = filteredSessions.filter((session) =>
          session.private ? session._id == params.id : true
        );

        setSessions(newFilteredSessions);
        if (!newFilteredSessions.length) return setFetchingSessions(false);
        if (!session) {
          history.replace("/order/" + newFilteredSessions[0]._id);
        }

        setFetchingSessions(false);
      })
      .catch((err) => {
        history.push("/");
      });
  }, []);

  useEffect(() => {
    if (params.id?.length !== 24) return;
    getWaitingTime(params.id).then((res) => {
      setWaitingTime(+res.data);
    });
  }, [params.id]);

  const session = sessions.find((item) => item._id === params.id);

  useEffect(() => {
    if (!session) return;
    if (!time && session.timeSlots) return;
    if (delivery) return;
    setOrderCardErrorMessage("");

    setOrderCardComplete(true);
  }, [time, delivery, params.id]);
  useEffect(() => {
    const intercepter = axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const status = error?.response?.status;
        const url = error?.config?.url;
        const method = error?.config?.method;

        if (!status || !url || !method) {
          return Promise.reject(error);
        }

        if (status >= 400 && url !== "/user/login") {
          setNetworkErrors([...networkErrors, error]);
        }
        // axiosErrors.current.push(error);

        return Promise.reject(error);
      }
    );
  }, []);
  const filteredPostcode = (postcode) => {
    return postcode
      .split("")
      .filter((e) => e.trim().length)
      .join("");
  };

  const scrollToMenu = () => {
    const element = document.getElementById("#menu");

    const height = element ? element.offsetTop : "1400";

    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };
  const scrollToOrderCard = () => {
    const element = document.getElementById("#sessionPanelBottom");

    const height = element ? element.offsetTop + 100 : "100";

    window.scrollTo({
      top: height - window.innerHeight,
      behavior: "smooth",
    });
  };

  const handleOrderCardSubmit = () => {
    if (!session) return setOrderCardErrorMessage("No Session Found");

    if (!time && session.timeSlots) {
      // scrollToOrderCard();
      return setOrderCardErrorMessage("Must pick a pick-up time");
    }
    if (delivery && session.delivery) {
      const cleanPostcode = filteredPostcode(postcode);

      if (!cleanPostcode) {
        // scrollToOrderCard();
        return setOrderCardErrorMessage("Add post code to order");
      }

      return getDeliveryFee(params.id, cleanPostcode)
        .then((res) => {
          setPostcode(cleanPostcode);
          setDeliveryFee(res.data);
          setOrderCardErrorMessage("");
          scrollToMenu();
          setOrderCardComplete(true);
        })
        .catch((err) => {
          // scrollToOrderCard();
          setDeliveryFee(0);
          setDelivery(false);
        });
    } else {
      setPostcode("");
      setDeliveryFee(0);
    }
    setOrderCardErrorMessage("");
    scrollToMenu();
    setOrderCardComplete(true);
  };

  const handleOrderSubmit = async (finalOrder) => {
    setStripeRedirect(true);
    try {
      const response = await createOrder(finalOrder);

      if (response?.data?.url) {
        window.location.href = response.data.url;
      } else {
        setStripeRedirect(false);
      }
    } catch (error) {
      setStripeRedirect(false);
    }
  };

  if (fetchingSessions) return <LoadingPage />;
  if (stripeRedirect) return <RedirectPage />;

  return (
    <Container disableGutters maxWidth="false">
      <NetworkErrorDisplay errors={networkErrors} />
      <Card
        style={{ backgroundColor: "#4da3fa", padding: "5% 0" }}
        square
        elevation={0}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            // align="center"
            style={{
              marginRight: "auto",
              fontSize: phone ? null : "10rem",
              // paddingRight: "20px",
            }}
          >
            Order Now
          </Typography>

          <br />
          <br />
          <Typography
            variant="h6"
            style={{
              maxWidth: "800px",
              marginLeft: "auto",
              fontSize: phone ? "1rem" : null,
            }}
          >
            Order Page for CleanCrust Takeaway. Scroll down to see when and
            where we will be selling pizzas next and to place your order.
          </Typography>
          <br />
          <br />
          <SessionPanel
            sessions={sessions}
            onSubmit={handleOrderCardSubmit}
            time={time}
            handleTimeChange={(time) => setTime(time)}
            phone={phone}
            postcode={postcode}
            setPostcode={setPostcode}
            delivery={delivery}
            orderCardErrorMessage={orderCardErrorMessage}
            setDelivery={(v) => {
              setDelivery(v);
              setOrderCardComplete(false);
            }}
            waitingTime={waitingTime}
            setOrderCardComplete={setOrderCardComplete}
          />
          <div id="#sessionPanelBottom"></div>
          <br />
          <br />
        </Container>
      </Card>
      <div id="#menu"></div>
      <Menu
        session={sessions.find((item) => item._id === params.id)}
        time={time}
        onReturn={() => setOrderCardComplete(false)}
        handleTimeChange={(e) => setTime(e.target.value)}
        postcode={postcode}
        deliveryFee={deliveryFee}
        orderCardComplete={orderCardComplete}
        onDisableClick={scrollToOrderCard}
        delivery={delivery}
        toggleStripeSubmit={() => setStripeRedirect(!stripeRedirect)}
        user={user}
        onSubmit={handleOrderSubmit}
      />
      <Footer />
    </Container>
  );
};

export default OrderPage;
