import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getActiveSession } from "../../api/session-apis";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  IconButton,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Basket = ({
  basket,
  deals,
  menu,
  handleRemoveDealFromBasket,
  handleRemovePizzaFromBasket,
  postcode,
  deliveryFee,
  price,
  discount,
}) => {
  const generateDeal = (index) => {
    const id = basket.deals[index].deal;
    const deal = deals.find((item) => item._id === id);
    return deal;
  };
  const generateDealSecondaryText = (pizzas) => {
    let names = [];

    menu.map((pizza) => {
      let count = 0;
      pizzas.map((item) => {
        if (item.name === pizza.name) return (count = count + 1);
      });
      if (!count) return null;
      names.push(" " + count + "x " + pizza.name);
    });
    return names.toString();
  };

  const generateModificationText = (modification, pizza) => {
    let stringArray = [];
    if (!modification) return;
    modification.modifications.forEach((index) => {
      stringArray.push(" " + pizza.modifications[index].name);
    });
    return stringArray.toString();
  };

  const findModificationAndRemoveFromArray = (id, array) => {
    let removeIndex = null;

    const modification = array.find((item, index) => {
      if (!item) return false;
      if (item.item === id) {
        removeIndex = index;
        return true;
      }
    });

    if (array[removeIndex]) {
      array[removeIndex] = null;
      return { modification: modification, modificationIndex: removeIndex };
    }
    return { modification: null, modificationIndex: null };
  };

  const generatePizzaPrice = (pizza, modification) => {
    let price = pizza.price;
    if (modification) {
      modification.modifications.forEach((index) => {
        price = price + pizza.modifications[index].price;
      });
    }
    return formatter.format(price);
  };

  const formatDiscount = ({ percentage, value }) => {
    if (percentage) {
      return value + "%";
    }
    return "- " + formatter.format(value);
  };

  const displayPrice = () => {
    if (!discount) return formatter.format(price);

    let newPrice = price;

    if (discount.percentage) {
      newPrice = newPrice * ((100 - discount.value) / 100);
    } else {
      newPrice = newPrice - discount.value;
      if (newPrice < 0) newPrice = 0;
    }

    return (
      <Grid container>
        <Typography
          style={{ textDecoration: "line-through", paddingRight: "5px" }}
          color="secondary"
        >
          {formatter.format(price)}
        </Typography>

        <Typography>{formatter.format(newPrice)}</Typography>
      </Grid>
    );
  };

  const generateNonDealsList = () => {
    let modifiedItems = [...basket.modifiedItems];
    let nonDeals = [...basket.nonDeals];

    return nonDeals.map((pizza, index) => {
      const { modification, modificationIndex } =
        findModificationAndRemoveFromArray(pizza._id, modifiedItems);

      return (
        <ListItem dense>
          <ListItemText
            primary={
              pizza.name + " - " + generatePizzaPrice(pizza, modification)
            }
            secondary={generateModificationText(modification, pizza)}
          />
          <ListItemSecondaryAction>
            <IconButton
              color="secondary"
              onClick={() =>
                handleRemovePizzaFromBasket(index, modificationIndex)
              }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <List>
      {basket.deals.map((deal, index) => {
        const dealObject = generateDeal(index);
        return (
          <>
            <ListItem dense>
              <ListItemText
                primary={dealObject.name}
                secondary={generateDealSecondaryText(deal.items)}
                style={{ padding: "2px" }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  color="secondary"
                  onClick={() => handleRemoveDealFromBasket(index)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </>
        );
      })}
      {generateNonDealsList()}
      {discount ? (
        <ListItem dense style={{ color: "rgb(77 163 250)" }}>
          <ListItemText
            primary={<strong>{"Discount: " + formatDiscount(discount)}</strong>}
            // style={{ padding: "2px" }}
          />
        </ListItem>
      ) : null}
      {deliveryFee ? (
        <ListItem>
          <ListItemText
            primary={"Delivery fee - " + formatter.format(deliveryFee)}
            secondary="£2.50 pick-up fee + £1 per km"
            style={{ padding: "2px" }}
          />
        </ListItem>
      ) : null}
      <Divider style={{ margin: "5px" }} />

      <ListItem>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography>Total: </Typography>
          <Typography>{displayPrice()}</Typography>
        </Grid>
      </ListItem>
    </List>
  );
};

export default Basket;
