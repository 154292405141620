import React from "react";

import {
  Card,
  CardContent,
  CardActions,
  Button,
  Tabs,
  Tab,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CustomButtomLink from "../customButtonLink";

import { useHistory, useParams } from "react-router-dom";
import { filterTimes } from "../../functions/timeFunctions";
import moment from "moment";
//icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  input: {
    // marginLeft: "10px",
    fontFamily: [
      "Fjalla One",
      "Segoe UI Black",
      "Roboto",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "1.25rem",
  },
}));

const OrderCard = ({
  sessions,
  onSubmit,
  time,
  handleTimeChange,
  fetching,
  phone,
  postcode,
  setPostcode,
  delivery,
  setDelivery,
  errorMessage,
  waitingTime,
  setOrderCardComplete,
  session,
}) => {
  let history = useHistory();

  const classes = useStyles();

  const handleSessionChange = (id) => {
    history.push("/order/" + id);
    handleTimeChange("");
    setPostcode("");
    setDelivery(false);
    setOrderCardComplete(false);
  };

  const handlePostcodeChange = (e) => {
    setPostcode(e.target.value);
  };

  const handleSetDelivery = (e, value) => {
    if (!session.delivery) return;
    setDelivery(!!value);
  };

  const generateDate = (date) => {
    return moment(date).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd [at] HH:mm",
      sameElse: "(DD/MM/YYYY)",
    });
  };

  if (!session)
    return (
      <Card
        style={{ width: "350px", height: "430px", marginTop: "10px" }}
        elevation={20}
      >
        <CardContent>
          <Typography
            variant="h2"
            style={{ fontSize: phone ? "2.5rem" : null }}
          >
            No Upcoming events found
          </Typography>
          <br />
          <Typography>
            We currently dont have any events that orders can be placed for.
            Follow on social media to keep up to date on when we will be selling
            pizzas next.
          </Typography>
          <br />
          <br />
          <Grid container justify="space-around" style={{ padding: "0 10%" }}>
            <Grid item>
              <CustomButtomLink
                url={{ pathname: "https://www.facebook.com/cleancrustuk/" }}
                target="_blank"
              >
                <img
                  src="/assets/facebookBlackIcon.png"
                  alt="Facebook icon"
                  style={{
                    height: "45px",
                    width: "45px",
                    paddingTop: "5px",
                  }}
                />
              </CustomButtomLink>
            </Grid>
            <Grid item>
              <CustomButtomLink
                url={{ pathname: "https://www.instagram.com/cleancrustuk/" }}
                target="_blank"
              >
                <img
                  src="/assets/instagramBlackIcon.png"
                  alt="instagram icon"
                  style={{
                    height: "45px",
                    width: "45px",
                    paddingTop: "5px",
                  }}
                />
              </CustomButtomLink>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );

  const isPreOrder = moment(session.date).isAfter(moment());
  if (session.timeSlots) {
    const filteredTimes = filterTimes(session.date, session.times);
    return (
      <Card
        style={{ width: "350px", height: "430px", marginTop: "10px" }}
        elevation={20}
      >
        <br />
        <DisplayDate
          date={generateDate(session.date)}
          sessions={sessions}
          handleSessionChange={handleSessionChange}
        />

        <Divider
          style={{
            backgroundColor: "#4da3fa",
            height: "2px",
            margin: "15px 0 0 0",
          }}
        />

        <Tabs
          value={delivery ? (session.delivery ? 1 : 0) : 0}
          indicatorColor="primary"
          // textColor="primary"
          onChange={handleSetDelivery}
          centered
          variant="fullWidth"
          scrollButtons="off"
        >
          <Tab
            style={{ textTransform: "none" }}
            label={<Typography variant="h5">Pick-Up</Typography>}
            fullWidth="true"
          />
          {session.delivery ? (
            <Tab
              style={{ textTransform: "none" }}
              label={<Typography variant="h5">Delivery</Typography>}
              fullWidth="true"
            />
          ) : null}
        </Tabs>
        <CardContent>
          <Typography style={{ width: "70vw" }}></Typography>
          <Typography align="center" variant="h5">
            <strong>{session.basesLeft > 0 ? session.basesLeft : "No"} </strong>
            bases remaining!
          </Typography>

          {filteredTimes.length ? (
            <Grid container alignItems="center" direction="column">
              <FormControl style={{ width: "70%" }}>
                <InputLabel id="time-select-label">
                  <Typography variant="h6">Time</Typography>
                </InputLabel>
                <Select
                  labelId="time-select-label"
                  id="time-select"
                  value={time}
                  onChange={(e) => handleTimeChange(e.target.value)}
                  style={{ minWidth: "60px" }}
                  classes={{ root: classes.input }}
                >
                  {filteredTimes.map((time) => (
                    <MenuItem value={time} key={time}>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: "1.25rem",
                        }}
                      >
                        {time}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <>
              <br />
              <Typography align="center" variant="h5">
                No Times remaining
              </Typography>
            </>
          )}

          {!delivery ? (
            <div style={{ height: "40px" }}></div>
          ) : (
            <DeliveryInput
              postcode={postcode}
              handlePostcodeChange={handlePostcodeChange}
            />
          )}
          <Typography
            color="error"
            align="center"
            style={{ width: "200px", margin: "auto" }}
          >
            {errorMessage}
          </Typography>
        </CardContent>

        <CardActions>
          <Button
            align="center"
            style={{ margin: "auto" }}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!session.basesLeft || !filteredTimes.length}
          >
            <Typography style={{ textTransform: "none" }} variant="h5">
              {isPreOrder ? "Pre-order Now" : "Order Now"}
            </Typography>
          </Button>
        </CardActions>
        <br />
      </Card>
    );
  }
  return (
    <Card
      style={{ width: "350px", height: "430px", marginTop: "10px" }}
      elevation={20}
    >
      <br />
      <DisplayDate
        date={generateDate(session.date)}
        sessions={sessions}
        handleSessionChange={handleSessionChange}
      />

      <Divider
        style={{
          backgroundColor: "#4da3fa",
          height: "2px",
          margin: "15px 0 0 0",
        }}
      />

      <Tabs
        value={delivery ? (session.delivery ? 1 : 0) : 0}
        indicatorColor="primary"
        // textColor="primary"
        onChange={handleSetDelivery}
        centered
        variant="fullWidth"
        scrollButtons="off"
      >
        <Tab
          style={{ textTransform: "none" }}
          label={<Typography variant="h5">Pick-Up</Typography>}
          fullWidth="true"
        />
        {session.delivery ? (
          <Tab
            style={{ textTransform: "none" }}
            label={<Typography variant="h5">Delivery</Typography>}
            fullWidth="true"
          />
        ) : null}
      </Tabs>
      <CardContent>
        <Typography style={{ width: "70vw" }}></Typography>
        <br />
        <Typography align="center" variant="h5">
          {isPreOrder ? (
            `Orders will open at ${moment(session.date).format("HH:mm")}`
          ) : (
            <>
              <strong>{waitingTime} </strong>
              minutes waiting time!
            </>
          )}
        </Typography>
        <br />
        <Typography align="center" variant="h5">
          <strong>{session.basesLeft ? session.basesLeft : "No"} </strong>
          bases remaining!
        </Typography>

        {delivery ? (
          <DeliveryInput
            postcode={postcode}
            handlePostcodeChange={handlePostcodeChange}
          />
        ) : (
          <div style={{ height: "40px" }}></div>
        )}
        <Typography
          color="error"
          align="center"
          style={{ width: "200px", margin: "auto" }}
        >
          {errorMessage}
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          align="center"
          style={{ margin: "auto" }}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!session.basesLeft || isPreOrder}
        >
          <Typography style={{ textTransform: "none" }} variant="h5">
            {isPreOrder ? "Pre-order Now" : "Order Now"}
          </Typography>
        </Button>
      </CardActions>
      <br />
    </Card>
  );
};

const DeliveryInput = ({ postcode, handlePostcodeChange }) => {
  const classes = useStyles();
  return (
    <Typography align="center">
      <TextField
        InputProps={{
          classes: { root: classes.input, formControl: classes.input },
        }}
        label={<Typography variant="h6">Postcode</Typography>}
        onChange={handlePostcodeChange}
        value={postcode}
        style={{ width: "70%", marginBottom: "10px" }}
      />
    </Typography>
  );
};

const DisplayDate = ({ date, sessions, handleSessionChange }) => {
  const params = useParams();
  const orderedSessions = [...sessions];
  orderedSessions.sort((sessionA, sessionB) => {
    return moment(sessionA.date).unix() - moment(sessionB.date).unix();
  });
  let currentindex = 0;
  const currentSession = orderedSessions.find((session, index) => {
    if (session._id === params.id) {
      currentindex = index;
      return true;
    }
  });

  const forward = currentindex + 1 !== sessions.length;
  const backward = !!currentindex;

  const handleForwardArrow = () => {
    if (!forward) return;
    handleSessionChange(sessions[currentindex + 1]._id);
  };
  const handleBackwardArrow = () => {
    if (!backward) return;
    handleSessionChange(sessions[currentindex - 1]._id);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="row"
      alignItems="center"
      justify="center"
      style={{ padding: "0 10%", objectFit: "cover", height: "80px" }}
    >
      <Grid item>
        <IconButton disabled={!backward} onClick={handleBackwardArrow}>
          <ArrowBackIosIcon />
        </IconButton>
      </Grid>
      <Grid>
        <Typography variant="h6" align="center">
          {generateNameFromSession(currentSession)}
        </Typography>
        <Typography variant="h5" align="center">
          {date}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton disabled={!forward} onClick={handleForwardArrow}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const generateNameFromSession = (session) => {
  const name = session?.popUp?.name;
  if (name) return name;
  return "Private Pop-Up";
};

export default OrderCard;
