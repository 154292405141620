import { React, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
//components

import {
  Grid,
  Container,
  Typography,
  Snackbar,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import MenuNav from "./menuNav";
import LoadingPage from "../loadingPage";
import PizzaCard from "./pizzaCard";
import DealCard from "./dealCard";
import BasketFooter from "./basketFooter";
import SubmitForm from "./submitForm";
import LoadingMenuCard from "./loadingMenuCard";

//icons
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

//functions
import { getMenu } from "../../api/menu-apis";
import { getDeals } from "../../api/deal-apis";
import { getActiveSessions } from "../../api/session-apis";

import { hasSessionEnded } from "../../functions/timeFunctions";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Menu = ({
  session,
  time,
  onReturn,
  handleTimeChange,
  postcode,
  deliveryFee,
  orderCardComplete,
  onDisableClick,
  displayWholeMenu,
  toggleStripeSubmit,
  user,
  onSubmit,
}) => {
  let history = useHistory();
  let params = useParams();
  let theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("xs"));
  const tablet = useMediaQuery(theme.breakpoints.down("sm"));

  const [fetchingMenu, setFetchingMenu] = useState(true);
  const [fetchingDeals, setFetchingDeals] = useState(true);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSession, setIsSession] = useState(false);
  const [menu, setMenu] = useState([]);
  const [deals, setDeals] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [basket, setBasket] = useState({
    nonDeals: [],
    deals: [],
    modifiedItems: [],
  });

  useEffect(() => {
    getMenu()
      .then((res) => {
        let newMenu = [...res.data].sort((a, b) => a.price - b.price);

        setMenu(newMenu);
        setFetchingMenu(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getDeals()
      .then((res) => {
        setDeals(res.data);
        setFetchingDeals(false);
      })
      .catch((err) => {
        console.log(err);
      });
    if (!session) {
      getActiveSessions().then((res) => {
        if (!res.data.length) return;
        const filteredSessions = res.data
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .filter((session) => !hasSessionEnded(session))
          .filter((session) => session.basesLeft);

        return filteredSessions.length ? setIsSession(true) : null;
      });
    }
  }, []);
  useEffect(() => {
    setBasket({ nonDeals: [], deals: [], modifiedItems: [] });
  }, [params.id]);
  //snackbar functions
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  //basket functions
  const checkBasesLeft = () => {
    let inBasket = basket.nonDeals.length;
    basket.deals.map((deal) => {
      inBasket = inBasket + deal.items.length;
    });
    return inBasket;
  };

  const handleAddDealToBasket = (deal, id) => {
    if (
      session.basesLeft <= checkBasesLeft() ||
      session.basesLeft < checkBasesLeft() + deal.length
    )
      return alert("no pizza bases left");

    setSnackbarOpen(true);
    const newDeal = { deal: id, items: deal };
    const newDeals = [...basket.deals, newDeal];

    setBasket({ ...basket, deals: newDeals });
  };

  const handleRemoveDealFromBasket = (index) => {
    const newDeals = [...basket.deals].filter((item, i) => i !== index);
    setBasket({ ...basket, deals: newDeals });
  };

  const handleAddPizzaToBasket = (pizza, modifications) => {
    if (session.basesLeft <= checkBasesLeft())
      return alert("no pizza bases left");

    setSnackbarOpen(true);

    const newPizzas = [...basket.nonDeals, pizza];

    if (!modifications.length) {
      setBasket({ ...basket, nonDeals: newPizzas });
    } else {
      const modifiedItem = {
        item: pizza._id,
        modifications: modifications,
      };
      setBasket({
        ...basket,
        nonDeals: newPizzas,
        modifiedItems: [...basket.modifiedItems, modifiedItem],
      });
    }
  };

  const handleRemovePizzaFromBasket = (pizzaIndex, modificationIndex) => {
    const newPizzas = [...basket.nonDeals].filter(
      (item, i) => i !== pizzaIndex
    );
    const newModifiedItems = [...basket.modifiedItems].filter(
      (item, index) => index !== modificationIndex
    );
    setBasket({
      ...basket,
      nonDeals: newPizzas,
      modifiedItems: newModifiedItems,
    });
  };

  //generate functions

  const calcBasket = () => {
    let items = basket.nonDeals.length;
    basket.deals.map((item) => (items = items + item.items.length));
    return items;
  };
  //submit functions
  const reformatArrayOfObjectsToIds = (array) => {
    return array.map((item) => item._id);
  };
  const handleOrderSubmit = async ({
    name,
    number,
    message,
    address,
    discount,
  }) => {
    setSubmitting(true);

    const newDeals = basket.deals.map((deal) => ({
      ...deal,
      items: reformatArrayOfObjectsToIds(deal.items),
    }));
    const newNonDeals = reformatArrayOfObjectsToIds(basket.nonDeals);

    let finalOrder = {
      name: name,
      number: number,
      deals: newDeals,
      nonDeals: newNonDeals,
      modifiedItems: basket.modifiedItems,
      extraInfo: message,
      session: params.id,
      time: time,
      delivery: address ? address : null,
    };

    if (discount?.code) finalOrder.discountCode = discount.code;

    await onSubmit(finalOrder);

    setSubmitting(false);
    setOpenSubmit(false);
  };
  const calcModifierPrices = () => {
    let price = 0;

    basket.modifiedItems.forEach((item) => {
      const modifications = basket.nonDeals.find(
        (nonDeal) => nonDeal._id === item.item
      ).modifications;
      item.modifications.forEach(
        (index) => (price = price + modifications[index].price)
      );
    });
    return price;
  };

  const generatePrice = () => {
    let price = postcode ? deliveryFee : 0;
    basket.deals.map((item) => {
      return (price =
        price + deals.find((deal) => deal._id === item.deal).price);
    });
    basket.nonDeals.map((item) => (price = price + item.price));
    price = price + calcModifierPrices();

    return price;
  };
  if (displayWholeMenu)
    return (
      <div>
        <MenuNav caterMe sides mains desserts />
        <Container>
          <Grid container direction="row-reverse">
            <Grid item container direction="column">
              <Container>
                <div id="#caterMe">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    CaterMe{" "}
                    <Tooltip
                      title="Here is the menu for the CaterMe package. If you select the
                    CaterMe+ or CaterMe Pro you can select from our entire menu!"
                    >
                      <InfoIcon style={{ paddingBottom: "50px" }} />
                    </Tooltip>
                  </Typography>

                  <br />
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter(
                        (item) =>
                          item.price <= 11 && !item.dessert && !item.drink
                      )

                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard pizza={item} phone={phone} disabled />
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <br />
                <div id="#sides">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    Sides
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter((item) => item.side && !item.drink)

                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard pizza={item} phone={phone} disabled />
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <br />
                <div id="#mains">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    Mains
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter((item) => !item.side && !item.dessert)

                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard
                            pizza={item}
                            onAddPizzaToBasket={handleAddPizzaToBasket}
                            phone={phone}
                            disabled={!orderCardComplete}
                            onDisabledClick={onDisableClick}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>{" "}
                <br />
                <div id="#desserts">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    Desserts
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter((item) => item.dessert)

                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard
                            pizza={item}
                            onAddPizzaToBasket={handleAddPizzaToBasket}
                            phone={phone}
                            disabled={!orderCardComplete}
                            onDisabledClick={onDisableClick}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <br />
              </Container>
            </Grid>
          </Grid>
        </Container>
        <div style={{ padding: "70px" }}></div>
      </div>
    );
  if (fetchingMenu || fetchingDeals || !session) return <LoadingPage />;
  let filteredDeals = deals.filter(
    (deal) => !session.ignoreDeals.includes(deal._id)
  );
  let filteredSides = menu
    .filter((item) => item.side)
    .filter((item) => !session.ignoreMenus.includes(item._id));

  let filteredMains = menu
    .filter((item) => !item.side && !item.dessert)
    .filter((item) => !session.ignoreMenus.includes(item._id));

  let filteredDesserts = menu
    .filter((item) => item.dessert)
    .filter((item) => !session.ignoreMenus.includes(item._id));

  if (params.id)
    return (
      <div>
        <MenuNav
          deals={filteredDeals.length}
          sides={filteredSides.length}
          mains={filteredMains.length}
          desserts={filteredDesserts.length}
        />
        <Container>
          <Grid container direction="row-reverse">
            <Grid item xs={0} md={4}>
              <br />
              <BasketFooter
                items={calcBasket()}
                basket={basket}
                deals={deals}
                menu={menu}
                handleSubmitClick={() => setOpenSubmit(true)}
                handleRemoveDealFromBasket={handleRemoveDealFromBasket}
                handleRemovePizzaFromBasket={handleRemovePizzaFromBasket}
                onReturn={onReturn}
                postcode={postcode}
                deliveryFee={deliveryFee}
                price={generatePrice()}
                phone={phone}
                tablet={tablet}
              />
            </Grid>
            <Grid item container direction="column" xs={12} md={8}>
              <Container>
                {/* {filteredDeals.length ? (
                  <div id="#deals">
                    <br />
                    <Typography
                      variant="h1"
                      component="h2"
                      style={{ fontSize: phone ? "4rem" : null }}
                    >
                      Deals
                    </Typography>
                    <br />
                    <Grid
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                      spacing={2}
                    >
                      {filteredDeals.map((item) => (
                        <Grid item key={item._id}>
                          <DealCard
                            deal={item}
                            menu={menu}
                            basket={basket}
                            handleAddDealToBasket={handleAddDealToBasket}
                            handleRemoveDealFromBasket={
                              handleRemoveDealFromBasket
                            }
                            phone={phone}
                            disabled={!orderCardComplete}
                            onDisabledClick={onDisableClick}
                            session={session}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ) : null} */}
                <div id="#sides">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    Sides
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter((item) => item.side)
                      .filter((item) => !session.ignoreMenus.includes(item._id))
                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard
                            pizza={item}
                            onAddPizzaToBasket={handleAddPizzaToBasket}
                            phone={phone}
                            disabled={!orderCardComplete}
                            onDisabledClick={onDisableClick}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <div id="#mains">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    Mains
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter((item) => !item.side && !item.dessert)
                      .filter((item) => !session.ignoreMenus.includes(item._id))
                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard
                            pizza={item}
                            onAddPizzaToBasket={handleAddPizzaToBasket}
                            phone={phone}
                            disabled={!orderCardComplete}
                            onDisabledClick={onDisableClick}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <div id="#desserts">
                  <br />
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: phone ? "4rem" : null }}
                  >
                    Desserts
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingMenuRow fetching={fetchingMenu} />
                    {menu
                      .filter((item) => item.dessert)
                      .filter((item) => !session.ignoreMenus.includes(item._id))
                      .map((item) => (
                        <Grid item key={item._id}>
                          <PizzaCard
                            pizza={item}
                            onAddPizzaToBasket={handleAddPizzaToBasket}
                            phone={phone}
                            disabled={!orderCardComplete}
                            onDisabledClick={onDisableClick}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <br />
              </Container>
            </Grid>
          </Grid>
        </Container>
        <div style={{ padding: "70px" }}></div>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={1000}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message="Order added to basket"
          key={"top" + "center"}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
        <SubmitForm
          open={openSubmit}
          onClose={() => setOpenSubmit(false)}
          onSubmit={handleOrderSubmit}
          submitting={submitting}
          basket={basket}
          deals={deals}
          menu={menu}
          handleRemoveDealFromBasket={handleRemoveDealFromBasket}
          handleRemovePizzaFromBasket={handleRemovePizzaFromBasket}
          time={time}
          session={session}
          handleTimeChange={handleTimeChange}
          postcode={postcode}
          deliveryFee={deliveryFee}
          price={generatePrice()}
          phone={phone}
          user={user}
        />
      </div>
    );
};

const LoadingMenuRow = ({ fetching }) => {
  if (!fetching) return null;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <LoadingMenuCard />
      </Grid>
      <Grid item>
        <LoadingMenuCard />
      </Grid>
      <Grid item>
        <LoadingMenuCard />
      </Grid>
    </Grid>
  );
};

export default Menu;
