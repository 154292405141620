import React from "react";

import { Grid } from "@material-ui/core";
import OrderCard from "./orderCard";
import LocationCard from "./locationCard";
import { useParams } from "react-router-dom";

const SessionPanel = ({
  sessions,
  onSubmit,
  time,
  handleTimeChange,
  phone,
  postcode,
  setPostcode,
  delivery,
  setDelivery,
  orderCardErrorMessage,
  waitingTime,
  setOrderCardComplete,
}) => {
  let params = useParams();
  const session = sessions.find((item) => item._id === params.id);
  return (
    <Grid container justifyContent="space-around">
      <LocationCard session={session} />
      <OrderCard
        sessions={sessions}
        onSubmit={onSubmit}
        time={time}
        handleTimeChange={handleTimeChange}
        phone={phone}
        postcode={postcode}
        setPostcode={setPostcode}
        delivery={delivery}
        setDelivery={setDelivery}
        errorMessage={orderCardErrorMessage}
        waitingTime={waitingTime}
        setOrderCardComplete={setOrderCardComplete}
        session={session}
      />
    </Grid>
  );
};

export default SessionPanel;
