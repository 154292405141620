import React from "react";
import { Link } from "react-router-dom";
import { ButtonBase, Typography } from "@material-ui/core";

const CustomButtomLink = ({ border, children, url, target, color }) => {
  return (
    <Link
      to={url}
      target={target}
      style={{ color: color ? color : "black", textDecoration: "none" }}
    >
      <ButtonBase
        style={{
          border: border ? "solid 3px " : null,
          borderRadius: "4px",
          height: "50px",
          width: "auto",
          marginTop: "5px",
        }}
      >
        <Typography variant="h6" style={{ padding: "5px 10px" }}>
          {children}
        </Typography>
      </ButtonBase>
    </Link>
  );
};

export default CustomButtomLink;
