import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Chip,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const InfoButton = ({ info }) => {
  const [show, setShow] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const allegens = [
    "molluscs",
    "soya",
    "sulphites",
    "lupin",
    "crustaceans",
    "fish",
    "sesame",
    "milk",
    "eggs",
    "mustard",
    "celery",
    "treenuts",
    "peanuts",
    "gluten",
  ];
  return (
    <>
      <IconButton
        onClick={() => setShow(true)}
        style={{ position: "absolute", right: 0, zIndex: "10" }}
      >
        <InfoIcon style={{ color: "white" }} />
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={() => setShow(false)}
      >
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <Typography>
            Your selected product contains the following allergens
          </Typography>
          <br />
          <Typography>
            {info.allegens.map((allegen) => (
              <Allegens allegen={allegen} />
            ))}
          </Typography>

          <br />
          <Typography>
            Our products are freshly prepared on site. We do not have dedicated
            preparation areas and there is a potential risk of cross
            contamination.
          </Typography>
          <br />
          <Nutrition nutrition={info.nutrition} />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setShow(!show)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InfoButton;

const Allegens = ({ allegen }) => {
  switch (allegen) {
    case "molluscs":
      return (
        <Chip
          label={"Molluscs"}
          icon={<img src="/assets/shell.png" alt="shell" />}
        />
      );
      break;
    case "soya":
      return (
        <Chip label={"Soya"} icon={<img src="/assets/soya.png" alt="soya" />} />
      );
      break;
    case "sulphites":
      return (
        <Chip
          label={"Sulphites"}
          icon={<img src="/assets/sulphite.png" alt="sulphite" />}
        />
      );
      break;
    case "lupin":
      return (
        <Chip
          label={"Lupin"}
          icon={<img src="/assets/lupin.png" alt="lupin" />}
        />
      );
      break;
    case "crustaceans":
      return (
        <Chip
          label={"Crustaceans"}
          icon={<img src="/assets/crustaceans.png" alt="crustaceans" />}
        />
      );
      break;
    case "fish":
      return (
        <Chip label={"Fish"} icon={<img src="/assets/fish.png" alt="fish" />} />
      );
      break;
    case "sesame":
      return (
        <Chip
          label={"Sesame"}
          icon={<img src="/assets/sesame.png" alt="sesame" />}
        />
      );
      break;
    case "milk":
      return (
        <Chip label={"Milk"} icon={<img src="/assets/milk.png" alt="milk" />} />
      );
      break;
    case "eggs":
      return (
        <Chip label={"Eggs"} icon={<img src="/assets/eggs.png" alt="eggs" />} />
      );
      break;
    case "mustard":
      return (
        <Chip
          label={"Mustard"}
          icon={<img src="/assets/mustard.png" alt="mustard" />}
        />
      );
      break;
    case "celery":
      return (
        <Chip
          label={"Celery"}
          icon={<img src="/assets/celery.png" alt="celery" />}
        />
      );
      break;
    case "treenuts":
      return (
        <Chip
          label={"Treenuts"}
          icon={<img src="/assets/treenuts.png" alt="treenuts" />}
        />
      );
      break;
    case "peanuts":
      return (
        <Chip
          label={"Peanuts"}
          icon={<img src="/assets/peanuts.png" alt="peanuts" />}
        />
      );
      break;
    case "gluten":
      return (
        <Chip
          label={"Gluten"}
          icon={<img src="/assets/gluten.png" alt="gluten" />}
        />
      );
      break;
    default:
      return null;
  }

  return null;
};

const Nutrition = ({ nutrition }) => {
  return (
    <TableContainer style={{ width: "100%" }}>
      <Table aria-label="nutrition table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">Calories&nbsp;(kcal)</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Saturates&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Sugar&nbsp;(g)</TableCell>
            <TableCell align="right">Salt&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
            <TableCell align="right">Fibre&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={nutrition.name}>
            <TableCell align="right">{nutrition.energy}</TableCell>
            <TableCell align="right">{nutrition.fats}</TableCell>
            <TableCell align="right">{nutrition.saturates}</TableCell>
            <TableCell align="right">{nutrition.carbs}</TableCell>
            <TableCell align="right">{nutrition.sugar}</TableCell>
            <TableCell align="right">{nutrition.salt}</TableCell>
            <TableCell align="right">{nutrition.protein}</TableCell>
            <TableCell align="right">{nutrition.fibre}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
