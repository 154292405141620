import React from "react";
import LoadingPage from "../../loadingPage";
import { Paper, Typography } from "@material-ui/core";

const RedirectPage = ({ phone }) => {
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(77, 163, 250)",

        height: "100%",
        width: "100%",
      }}
    >
      <LoadingPage style={{ paddingTop: "10%" }} noText />
      <br />
      <Typography align="center" variant="h5">
        You are being redirected to{" "}
      </Typography>

      <Typography align="center" variant="h5">
        <img
          src="/assets/stripe.svg"
          alt="stripes logo"
          style={{ height: "50px" }}
        />
      </Typography>
    </Paper>
  );
};

export default RedirectPage;
