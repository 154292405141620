import React, { useEffect, useState, useContext } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const NetworkErrorDisplay = ({ errors }) => {
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   let isMounted = true;
  //   const intercepter = axios.interceptors.response.use(
  //     function (response) {
  //       // Any status code that lie within the range of 2xx cause this function to trigger
  //       // Do something with response data
  //       return response;
  //     },
  //     function (error) {
  //       // Any status codes that falls outside the range of 2xx cause this function to trigger
  //       // Do something with response error
  //       const status = error?.response?.status;
  //       const url = error?.config?.url;
  //       const method = error?.config?.method;

  //       if (!status || !url || !method) {
  //         return Promise.reject(error);
  //       }

  //       if (url === "/user" && method === "get" && status === 401) {
  //         return Promise.reject(error);
  //       }
  //       if (url === "/user/login" && method === "post") {
  //         return Promise.reject(error);
  //       }

  //       if (status >= 400) {
  //         setAxiosErrors([...axiosErrors, error]);
  //       }
  //       // axiosErrors.current.push(error);

  //       return Promise.reject(error);
  //     }
  //   );
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    console.log("run hook", errors);
    if (!errors?.length) return;
    if (errors[errors.length - 1]) {
      setOpen(true);
    }
  }, [errors]);
  const generateErrorMessage = () => {
    const error = errors[errors?.length - 1];

    const errorMessage =
      typeof error?.response?.data === "string"
        ? error?.response?.data
        : error?.response?.data?.message;

    const status = error?.response?.status;
    const message = errorMessage || "Network Error";
    return (status ? status + ": " : "") + message;
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={8000}
      onClose={handleClose}
      // anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="error">
        <AlertTitle variant="h4">Request Error</AlertTitle>
        <Typography variant="h6">{generateErrorMessage()}</Typography>
      </Alert>
    </Snackbar>
  );
};

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export default NetworkErrorDisplay;
