import axios from "axios";
import { url } from "./apiurl";

export const getOrders = () =>
  axios.get(url + "/orders", { withCredentials: true });

export const createOrder = (order) =>
  axios.post(url + "/orders", order, { withCredentials: true });

export const completeOrder = (id) =>
  axios.patch(url + "/orders/complete/" + id, { withCredentials: true });
export const prepareOrder = (id) =>
  axios.patch(url + "/orders/prepare/" + id, { withCredentials: true });

export const changeOrderTime = (time, id) =>
  axios.patch(url + "/orders/" + id + "/changeTime/" + time);

export const removeOrderFromSession = (orderID) =>
  axios.delete(url + "/orders/" + orderID);

export const updateOrder = (id, order) =>
  axios.patch(url + "/orders/" + id, order, {
    withCredentials: true,
  });

export const payOrder = (orderID, payload) =>
  axios.patch(url + "/orders/pay/" + orderID, payload, {
    withCredentials: true,
  });
