import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  ClickAwayListener,
  useMediaQuery,
  CircularProgress,
  Card,
  CardContent,
  CardActionArea,
  SvgIcon,
} from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../logo.svg";
import { ReactComponent as LogoIcon } from "../logoIcon.svg";

import moment from "moment";

import MenuIcon from "@material-ui/icons/Menu";
import CustomButtomLink from "./customButtonLink";

import CloseIcon from "@material-ui/icons/Close";

import { useHistory, useLocation } from "react-router-dom";
import { loginUser } from "../api/user-apis";
import { getActiveSessions, querySessions } from "../api/session-apis";
// import MenuIcon from "@material-ui/icons/Menu";

const Navbar = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [fetchingLogin, setFetchingLogin] = useState(true);
  const [activeSessions, setActiveSessions] = useState([]);

  const theme = useTheme();
  const phone = !useMediaQuery(theme.breakpoints.up("md"));
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    loginUser()
      .then((res) => {
        setLoggedIn(true);
        setAdmin(res.data.admin);
        setFetchingLogin(false);
        if (res.data.admin) {
          querySessions({
            startDate: moment().startOf("day").format(),
            endDate: moment().endOf("day").format(),
          }).then((res) => {
            setActiveSessions(res.data);
          });
        }
      })
      .catch((err) => {
        setFetchingLogin(false);
      });
  }, []);

  const generateBackgroundColor = () => {
    const path = location.pathname;
    if (path.indexOf("about") !== -1) return "#FAA34C";
    if (path.indexOf("workshops") !== -1) return "#A3E85F";
    if (path.indexOf("catering") !== -1) return "#9883E5";
    if (path.indexOf("thankyou") !== -1) return "#4DA3FA";
    if (path.indexOf("ethicalandsustainable") !== -1) return "rgb(219 172 152)";

    return "#4DA3FA";
  };

  const handleClick = (event) => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    if (expanded) {
      setExpanded(false);
    }
  };
  const handleMenuClick = () => {
    handleClose();
    history.push("/menu");
  };
  const handleContactClick = () => {
    handleClose();
    history.push("/contact");
  };
  if (phone) {
    return (
      <AppBar
        position="static"
        style={{
          zIndex: "1201",
          backgroundColor: generateBackgroundColor(),
        }}
      >
        <ActiveSessions sessions={activeSessions} history={history} />
        <ClickAwayListener onClickAway={handleClose}>
          <Toolbar style={{ minHeight: "70px" }}>
            <Accordion
              style={{
                width: "100%",
                backgroundColor: "inherit",
                boxShadow: "none",
              }}
              expanded={expanded}
              onChange={handleClose}
            >
              <AccordionSummary style={{ height: "90px" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.push("/")}
                  >
                    <Grid container alignItems="center" direction="row">
                      <SvgIcon
                        component={LogoIcon}
                        viewBox="0 0 700 750"
                        style={{
                          fontSize: "50px",
                          marginTop: "-8px",
                        }}
                      />
                      <Typography variant="h4" style={{ marginTop: "0px" }}>
                        CleanCrust
                      </Typography>
                    </Grid>
                  </IconButton>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    style={{ marginLeft: "auto" }}
                    onClick={handleClick}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  item
                  container
                  spacing={2}
                  direction="column"
                  onClick={handleClose}
                >
                  <Links
                    admin={admin}
                    loggedIn={loggedIn}
                    fetchingLogin={fetchingLogin}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Toolbar>
        </ClickAwayListener>
      </AppBar>
    );
  }
  return (
    <AppBar
      position="static"
      style={{
        zIndex: "1201",
        backgroundColor: generateBackgroundColor(),
      }}
    >
      <ActiveSessions sessions={activeSessions} history={history} />
      <Toolbar
        style={{
          maxHeight: "125px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          style={{
            maxHeight: "125px",
          }}
        >
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => history.push("/")}
            >
              <SvgIcon
                component={Logo}
                viewBox="0 0 1200 700"
                style={{
                  fontSize: "160px",

                  marginTop: "-25px",
                }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
            >
              <Grid
                item
                container
                spacing={0}
                direction="row-reverse"
                alignItems="flex-start"
                // justifyContent="flex-end"
                style={{ paddingTop: "20px" }}
                // xs={6}
              >
                <Grid item style={{ marginLeft: "10px" }}>
                  <CustomButtomLink border url={"/order"}>
                    Order Now
                  </CustomButtomLink>
                </Grid>
                <Grid item>
                  <CustomButtomLink
                    url={{ pathname: "https://www.facebook.com/cleancrustuk/" }}
                    target="_blank"
                  >
                    <img
                      src="/assets/facebookBlackIcon.png"
                      alt="Facebook icon"
                      style={{
                        height: "45px",
                        width: "45px",
                        paddingTop: "5px",
                      }}
                    />
                  </CustomButtomLink>
                </Grid>
                <Grid item>
                  <CustomButtomLink
                    url={{
                      pathname: "https://www.instagram.com/cleancrustuk/",
                    }}
                    target="_blank"
                  >
                    <img
                      src="/assets/instagramBlackIcon.png"
                      alt="instagram icon"
                      style={{
                        height: "45px",
                        width: "45px",
                        paddingTop: "5px",
                      }}
                    />
                  </CustomButtomLink>
                </Grid>
              </Grid>
              <br />
              <Grid
                item
                container
                spacing={2}
                // direction="row-reverse"
                // justifyContent="flex-end"
                // xs={6}
                onClick={handleClose}
              >
                <Links
                  admin={admin}
                  loggedIn={loggedIn}
                  fetchingLogin={fetchingLogin}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <br />
    </AppBar>
  );
};

const ActiveSessions = ({ sessions, history }) => {
  const [open, setOpen] = useState(true);

  const generateName = (session) => {
    if (session.private) {
      if (session?.contact?.name) return session.contact.name;
      return session.popUp?.name || "Private Event";
    }

    return session.popUp?.name || "Public Event";
  };
  if (!sessions.length || !open) return null;

  return (
    <Grid
      container
      style={{
        width: "100%",
        backgroundColor: "limegreen",
        position: "relative",
      }}
      className="background-active-sessions"
      justifyContent="space-around"
    >
      {sessions.map((session) => (
        <Grid item key={session._id}>
          <Card style={{ backgroundColor: "#66dc66" }}>
            <CardActionArea
              onClick={() => history.push(`/admin/session/${session._id}`)}
            >
              <CardContent>
                <Typography variant="h5">{generateName(session)}</Typography>
                <Typography variant="body">
                  {moment(session.date).fromNow()}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      <IconButton
        onClick={() => setOpen(false)}
        style={{ position: "absolute", right: 0, top: "15px" }}
      >
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};

const PersonalisedLink = ({ name, url, fetching }) => {
  return (
    <Grid item direction="row">
      <NavLink
        to={url}
        activeStyle={{ color: "white" }}
        style={{ color: "black", textDecoration: "none" }}
      >
        <Typography variant="h6">
          {name}
          {fetching ? (
            <>
              {"  "}
              <CircularProgress color="inherit" size={20} />
            </>
          ) : null}
        </Typography>
      </NavLink>
    </Grid>
  );
};

const Links = ({ loggedIn, admin, fetchingLogin }) => {
  return (
    <>
      <PersonalisedLink name="Order" url="/order" />
      <PersonalisedLink name="About" url="/about" />
      <PersonalisedLink name="Catering" url="/catering" />
      <PersonalisedLink name="Workshops" url="/workshops" />
      {loggedIn ? (
        <PersonalisedLink name="My Account" url="/user" />
      ) : (
        <PersonalisedLink
          name="Login"
          url="/user/login"
          fetching={fetchingLogin}
        />
      )}
      {admin ? <PersonalisedLink name="Admin" url="/admin/session" /> : null}
    </>
  );
};

export default Navbar;
