import axios from "axios";
import { url } from "./apiurl";
import generateQueryString from "./functions/generateQueryString";

export const getActiveSessions = () =>
  axios.get(url + "/session", { withCredentials: true });

export const querySessions = (query) =>
  axios.get(url + "/session/?" + generateQueryString(query));

export const createNewSession = (payload) =>
  axios.post(url + "/session", payload, { withCredentials: true });

export const getSession = (id) =>
  axios.get(url + "/session/" + id, { withCredentials: true });

export const incrementBases = (id, number) =>
  axios.patch(url + "/session/" + id + "/incrementBases/" + number, {
    withCredentials: true,
  });

export const updateSession = (id, payload) =>
  axios.patch(url + `/session/${id}`, payload, { withCredentials: true });

export const completeSession = (id) =>
  axios.patch(url + `/session/${id}/complete`, { withCredentials: true });

export const toggleTimeInSession = (id, time) =>
  axios.patch(url + "/session/" + id + "/toggleTime/" + time);

export const getDeliveryFee = (id, postcode) =>
  axios.get(url + "/session/" + id + "/delivery/" + postcode, {
    withCredentials: true,
  });

export const getWaitingTime = (id) =>
  axios.get(url + "/session/" + id + "/waitingTime", {
    withCredentials: true,
  });

export const getDistanceToSession = (id) =>
  axios.get(url + "/session/" + id + "/distance", {
    withCredentials: true,
  });
