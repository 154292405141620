import React, { Component, useEffect } from "react";

import { Container, Grid, Typography, Button } from "@material-ui/core";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { postError } from "../api/error-apis";

const GenericError = ({ error, resetErrorBoundary, children }) => {
  const theme = createTheme({
    typography: {
      h1: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h2: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h3: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h4: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h5: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h6: {
        fontFamily: [
          "Fjalla One",
          "Segoe UI Black",
          "Roboto",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    },
    palette: {
      primary: {
        main: "#4da3fa",
      },
    },
  });
  useEffect(() => {
    if (!process?.env?.NODE_ENV || process?.env?.NODE_ENV === "development") {
    } else {
      postError(error.toString());
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <br />
        <br />
        <Grid container justifyContent="space-around" direction="column">
          <Grid item xs={12}>
            <img
              src="/assets/workingOnPcCartoon.png"
              alt="Cartoon of two chefs working on a analog computer"
              style={{ width: "80%", padding: "10%" }}
            />
          </Grid>
        </Grid>
        <Typography variant="h2" align="center">
          Something went Wrong....
        </Typography>
        <br />
        <Typography variant="h6" align="center">
          This error has been sent to us and we are now working to fix it!
        </Typography>
        <br />
        <br />
        <Grid container justifyContent="space-around">
          <Button
            variant="outlined"
            color="primary"
            onClick={resetErrorBoundary}
          >
            <Typography variant="h4">Reload Page</Typography>
          </Button>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

// class GenericError extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hasError: false,
//     };
//   }
//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }
//   render() {
//     if (this.state.hasError)
//       return (
//         <Container maxWidth="sm">
//           <br />
//           <br />
//           <Grid container justifyContent="space-around" direction="column">
//             <Grid item xs={12}>
//               <img
//                 src="/assets/workingOnPcCartoon.png"
//                 alt="Cartoon of two chefs working on a analog computer"
//                 style={{ width: "80%", padding: "10%" }}
//               />
//             </Grid>
//           </Grid>
//           <Typography variant="h2" align="center">
//             Something went Wrong....
//           </Typography>
//           <br />
//           <Typography variant="h6" align="center">
//             This error has been sent to us and we are now working to fix it!
//           </Typography>
//         </Container>
//       );
//     return this.props.children;
//   }
// }

export default GenericError;
