import { React, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Badge,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Hidden,
  Card,
  CardContent,
  Divider,
  ButtonBase,
  Slide,
} from "@material-ui/core";
import Basket from "./basket";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const BasketFooter = ({
  items,
  basket,
  deals,
  menu,
  handleRemovePizzaFromBasket,
  handleRemoveDealFromBasket,
  handleSubmitClick,
  postcode,
  deliveryFee,
  price,
  tablet,
  admin,
}) => {
  const [openBasket, setOpenBasket] = useState(false);

  const handleCloseBasket = () => {
    setOpenBasket(false);
  };
  const handleSubmit = () => {
    setOpenBasket(false);
    handleSubmitClick();
  };

  let disabled = !basket.deals.length && !basket.nonDeals.length;

  if (!tablet)
    return (
      <>
        <Card
          style={{ width: "300px", position: "sticky", top: 10, zIndex: 1200 }}
          elevation={10}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            style={{
              width: "100%",
              height: "50px",
              color: disabled ? null : "white",
            }}
            onClick={handleSubmitClick}
          >
            Go to Checkout
          </Button>
          <Basket
            basket={basket}
            deals={deals}
            menu={menu}
            handleRemoveDealFromBasket={handleRemoveDealFromBasket}
            handleRemovePizzaFromBasket={handleRemovePizzaFromBasket}
            postcode={postcode}
            deliveryFee={deliveryFee}
            price={price}
          />
        </Card>
        <Dialog
          onClose={handleCloseBasket}
          aria-labelledby="customized-dialog-title"
          open={openBasket}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ minWidth: "300px" }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid>Your Basket</Grid>
              <Grid>{formatter.format(price)}</Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Basket
              basket={basket}
              deals={deals}
              menu={menu}
              handleRemoveDealFromBasket={handleRemoveDealFromBasket}
              handleRemovePizzaFromBasket={handleRemovePizzaFromBasket}
              postcode={postcode}
              deliveryFee={deliveryFee}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseBasket} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        style={{
          bottom: "0px",
          top: "auto",
          padding: "5px",
          zIndex: "1250",
          width: "95%",
          borderRadius: "15px",
          left: "2.5%",
          height: "50px",
          visibility: disabled ? "hidden" : null,
        }}
      >
        <ButtonBase
          style={{ width: "100%", height: "100%" }}
          onClick={() => (admin ? handleSubmitClick() : setOpenBasket(true))}
          // disabled={!basket.deals.length && !basket.nonDeals.length}
        >
          <Typography
            align="center"
            variant="button"
            style={{ color: "white" }}
          >
            {admin ? "Checkout" : "View Basket"}
          </Typography>
          <Typography
            style={{ color: "white", right: "5px", position: "absolute" }}
            variant="button"
          >
            {formatter.format(price)}
          </Typography>
        </ButtonBase>
      </AppBar>
      <Dialog
        onClose={handleCloseBasket}
        aria-labelledby="customized-dialog-title"
        open={openBasket}
        fullScreen={true}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid>Your Basket</Grid>
            <Grid>{formatter.format(price)}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Basket
            basket={basket}
            deals={deals}
            menu={menu}
            handleRemoveDealFromBasket={handleRemoveDealFromBasket}
            handleRemovePizzaFromBasket={handleRemovePizzaFromBasket}
            postcode={postcode}
            deliveryFee={deliveryFee}
            price={price}
          />
        </DialogContent>
        <DialogActions>
          <Grid container direction="column">
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", width: "100%", height: "50px" }}
              onClick={handleSubmit}
              disabled={!basket.deals.length && !basket.nonDeals.length}
            >
              Go to Checkout
            </Button>
            <Button
              color="primary"
              style={{ width: "100%", height: "50px" }}
              onClick={handleCloseBasket}
            >
              Return to Menu
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BasketFooter;
