import React from "react";
import { Container, Paper, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as LogoIcon } from "../fullPizza.svg";

import "./styles.css";

const LoadingPage = ({ style, noText }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <Grid item>
        <SvgIcon
          component={LogoIcon}
          viewBox="0 0 1000 1000"
          className="rotate"
          style={{ fontSize: "300px" }}
          // style={{ animation: "rotation 2s infinite linear" }}
        />
      </Grid>
      {noText ? null : (
        <Grid item>
          <Typography
            variant="h1"
            style={{
              marginBottom: "-20px",
              paddingLeft: "10px",
              marginTop: "-30px",
            }}
          >
            Clean
          </Typography>
          <Typography
            variant="h1"
            style={{ marginBottom: "-20", paddingLeft: "10px" }}
          >
            Crust
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LoadingPage;
