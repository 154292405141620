import { React, useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CardActions,
  Typography,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import VeganIcon from "./veganIcon";
import VegeterianIcon from "./vegetarianIcon";
import InfoButton from "./infoButton";
import AddToBasketModal from "./addToBasketModal";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const PizzaCard = ({
  pizza,
  onAddPizzaToBasket,
  deal,
  phone,
  disabled,
  onDisabledClick,
}) => {
  const cardRef = useRef(null);
  const [modal, setModal] = useState(false);
  const interactive = !(disabled && !onDisabledClick);

  let viewPortWidth = window.innerWidth;

  const handleClick = (modifications) => {
    onAddPizzaToBasket(pizza, modifications);
  };

  const handlePizzaClick = () => {
    if (disabled) return onDisabledClick();
    setModal(true);
  };

  //add number functionality one above
  const handleImg = () => {
    switch (pizza.name) {
      case "Margherita":
        return "/assets/marg.jpg";

      case "Dough strips":
        return "/assets/garlicDoughStrips.jpg";
      case "Nutella Dough Strips":
        return "/assets/nutellaDoughStrips.png";
      case "The Meaty One":
        return "/assets/meatyOne.png";

      case "Veggie":
        return "/assets/veg.jpg";
      case "Ham and Mushroom":
        return "/assets/ham.jpg";
      case "Pepperoni":
        return "/assets/pep.jpg";

      case "Garlic bread":
        return "/assets/garlic.jpg";
      case "Dough Balls":
        return "/assets/balls.jpg";
      case "Marinara":
        return "assets/marinara.jpg";

      case "Dessert Pizza":
        return "/assets/dessert.jpg";

      case "Nutella Dough Balls":
        return "/assets/nutellaBalls.jpg";
      case "Vegan":
        return "/assets/vegan.jpg";
      case "The Spanish":
        return "/assets/theSpanish.jpg";
      case "Apple Pie":
        return "/assets/applePie.jpg";

      case "The Squashed Pig":
        return "/assets/theSquashedPig.jpg";
      case "Hot n' Spicy":
        return "/assets/hotNSpicy.jpg";
      case "Blue n' Sweet":
        return "/assets/blueNSweet.jpg";
      case "Santa Claus":
        return "/assets/santaClaus.jpg";

      default:
        return "/assets/pictureComingSoon.png";
    }
  };

  return (
    <>
      <Card
        style={{
          maxWidth: "350px",
          position: "relative",
        }}
        ref={cardRef}
        elevation={5}
      >
        <InfoButton info={pizza.info} />
        <CardActionArea onClick={interactive ? handlePizzaClick : null}>
          <CardMedia
            style={{ height: "0", padding: "40%" }}
            image={handleImg()}
            title={pizza.name}
          />

          <CardContent
            style={{
              // height: "100px",
              position: "relative",
            }}
          >
            <Grid container justify="space-between">
              <Typography gutterBottom variant="h5" component="h2">
                {pizza.name}
              </Typography>
              <Typography gutterBottom variant="h5" component="h2">
                {deal || !interactive ? null : formatter.format(pizza.price)}
              </Typography>
            </Grid>
            <br />
            <Typography style={{ height: "80px" }}>
              {pizza.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <AddToBasketModal
        show={modal}
        onClose={() => setModal(false)}
        pizza={pizza}
        onSubmit={handleClick}
        deal={deal}
        phone={phone}
      />
    </>
  );
};

export default PizzaCard;
